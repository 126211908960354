/* 
https://www.iconfinder.com/icons/1243689/call_phone_icon
Creative Commons (Attribution 3.0 Unported);
https://www.iconfinder.com/Makoto_msk */

export const storeProducts = [
  {
    id: 1,
    title: "Notebook Gamer TUF F15 FX507",
    img: "img/527289-0000-001.png",
    price: 639990,
    company: "Asus",
    info:
      "Intel Core i5-12500H NVIDIA GeForce RTX 3050 4GB 15.6 144 hz FHD 8gb 512 GB",
    inCart: false,
    count: 0,
    total: 0
  },
  {
    id: 2,
    title: "Diccionario Mini Italiano",
    img: "img/9788494728846_1041x.png",
    price: 16000,
    company:"Sopena",
    info:
      "Editorial: SOPENA Autor: Ramón Sopena Edad: +10 Tipo Encuadernado: Tapa Dura Papel Interior: Bond Páginas: 520 Ancho: 9 Alto: 14.",
    inCart: false,
    count: 0,
    total: 0
  },
  {
    id: 3,
    title: "Iphone 13 128 GB WHITE",
    img: "img/iphone13-blanco-estelar_01_276x549.png",
    price: 850900,
    company: "Apple",
    info:
      "Cámara trasera 12 + 12 MP Cámara frontal 12 MP Pantalla 6.1 Batería 3240 mAh Almacenamiento 128 GB Procesador A15 Bionic.",
    inCart: false,
    count: 0,
    total: 0
  },
  {
    id: 4,
    title: "Mancuerna ajustable 10 kg",
    img: "img/8727333_01.png",
    price: 25000,
    company: "BodyTone",
    info:
      "Garantía_	6 meses Material_	Acero Color	Negro Número de piezas	1 Marca	Bodytone Peso del producto	10.3 kg.",
    inCart: false,
    count: 0,
    total: 0
  },
  {
    id: 5,
    title: "HTC Desire 626s",
    img: "img/product-5.png",
    price: 24000,
    company: "htc",
    info:
      "Celular de la marca htc vintage de los años 2015-2017.",
    inCart: false,
    count: 0,
    total: 0
  },
  {
    id: 6,
    title: "PACO RABANNE 1 MILLION PARFUM 100 ML",
    img: "img/Paco-Rabanne-1-Million-Parfum-100-ML-Hombre-Cosmetic-cl.png",
    price: 70000,
    company: "Paco Rabanne",
    info:
      "1 MILLION PARFUM El perfume 1 Million de Paco Rabanne para hombre tiene la audacia del cuero más genuino. Un aroma masculino intenso con la energía propia del sol. La fragancia desafía los efectos de la materia y juega con las sensaciones.",
    inCart: false,
    count: 0,
    total: 0
  },
  {
    id: 7,
    title: "Zapatillas Harden Volume 8",
    img: "img/Hardenvol8.png",
    price: 130000,
    company: "Adidas",
    info:
      "Las harden volumen 8 son las zapatillas más nuevas de la linea harden. Provenientes del jugador de la NBA James Harden. Luciendo una combinación de adidas BOOST y mediasuela Lightstrike, estas zapatillas de básquet de alto rendimiento proporcionan el soporte que necesitas para dominar cada jugada.",
    inCart: false,
    count: 0,
    total: 0
  },
  {
    id: 8,
    title: "Dc Comics: Batman e Hijo: 8",
    img: "img/f5d206d0da7dc7fdb78eb55343375489.png",
    price: 66400,
    company: "DC COMICS",
    info:
      "Increíble colección de cómics en tapa dura que reúne las mejores aventuras de super héroes de la edad moderna con guiones e ilustraciones de los mejores creadores de DC comics. Editorial Salvat Año 2016 Idioma Español N° páginas 176 Encuadernación Tapa Dura.",
    inCart: false,
    count: 0,
    total: 0
  }
];

export const detailProduct = {
  id: 1,
  title: "Google Pixel - Black",
  img: "img/product-1.png",
  price: 10,
  company: "google",
  info:
    "Lorem ipsum dolor amet offal butcher quinoa sustainable gastropub, echo park actually green juice sriracha paleo. Brooklyn sriracha semiotics, DIY coloring book mixtape craft beer sartorial hella blue bottle. Tote bag wolf authentic try-hard put a bird on it mumblecore. Unicorn lumbersexual master cleanse blog hella VHS, vaporware sartorial church-key cardigan single-origin coffee lo-fi organic asymmetrical. Taxidermy semiotics celiac stumptown scenester normcore, ethical helvetica photo booth gentrify.",
  inCart: false,
  count: 0,
  total: 0
};
